import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import { CircularProgress, type Theme } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {
  DataGrid,
  type GridColDef,
  type GridInitialState,
  type GridRenderCellParams,
  GridToolbar
} from '@mui/x-data-grid';
import axios from 'axios';
import Icon from '../../../ui/shared/Icon';
import IconButton from '../../../ui/shared/IconButton';
import ReportModal from '../../../ui/shared/Modals/reports/RunReport.modal';
import { appState, useSignal, handleDataGridStateChange } from '../../../index';
import { openToast } from '../../../helpers/toast';
import { type ScheduledReport, type Report } from '../../../types/reports.d';
import ScheduleReportModal from '../../../ui/shared/Modals/reports/ScheduleReport.modal';

const theme: Theme = createTheme({
  palette: {
    mode: 'dark'
  }
});

interface PaginationModel {
  page: number;
  pageSize: number;
}

/**
 * Transform each report from the backend.
 */
const transformReport = (reports: any[]): ScheduledReport[] => {
  return reports.map((report: any): ScheduledReport => {
    return {
      id: report.id,
      accountId: report.account_id,
      email: report.email ? report.email : 'N/A',
      day: report.day,
      interval: report.interval,
      time: report.time,
      alerts: report.alerts,
      type: report.type,
      timezone: report.timezone
    };
  });
};

const Reports: React.FC = () => {
  const updatedAccountId = useSignal(appState.accountId);
  const [scheduledReports, setScheduledReports] = useState<ScheduledReport[]>([]);
  const [existingScheduledReport, setExistingScheduledReport] = useState<Report | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isScheduledReportModalOpen, setIsScheduledReportModalOpen] = useState<boolean>(false);
  const [isRunReportModalOpen, setIsRunReportModalOpen] = useState<boolean>(false);
  const [isEditScheduledReportModalOpen, setIsEditScheduledReportModalOpen] = useState<boolean>(false);
  const [rowCount, setRowCount] = useState<number>(0);
  const [rowCountState, setRowCountState] = useState<number>(rowCount);
  const [paginationModel, setPaginationModel] = useState<PaginationModel>({
    page: 0,
    pageSize: 50
  });

  const fetchScheduledReports = async (model: PaginationModel): Promise<void> => {
    setLoading(true);
    const { page, pageSize } = model;
    const url: string =
      `${process.env.REACT_APP_API_URL}/api/paginated-report-schedules` +
      `?filter=account_id:${appState.accountId.value}(eq)&page=${page + 1}&pageSize=${pageSize}`;
    try {
      const response = await axios.get(url);
      setScheduledReports(transformReport(response.data.data));
      setRowCount(response.data.meta.total);
    } catch (err: any) {
      if (err?.response?.status === 429) {
        console.log('Too many requests', err);
        openToast({
          type: 'error',
          label: t('Too many requests, try again in a few minutes'),
          autoClose: 2000,
          theme: 'dark'
        });
      } else {
        console.log(err);
        openToast({
          type: 'error',
          label: t('Error fetching reports'),
          autoClose: 2000,
          theme: 'dark'
        });
      }
    }
    setLoading(false);
  };

  /**
   * A helper to convert the stored interval to a start date.
   */
  const formatIntervalToDate = (today: Date, report: any): Date => {
    switch (report.interval) {
      case 'monthly':
        today.setDate(today.getDate() - 28);
        return today;
      case 'biweekly':
        today.setDate(today.getDate() - 14);
        return today;
      case 'weekly':
        today.setDate(today.getDate() - 7);
        return today;
      default:
        return today;
    }
  };

  /**
   * Run a scheduled report.
   */
  const runScheduledReport = async (report: any, action: 'download' | 'email'): Promise<void> => {
    const url = `${process.env.REACT_APP_API_URL}/api/run-scheduled-report`;
    setLoading(true);
    try {
      const response = await axios({
        url,
        data: { report_schedule_id: report.id, action },
        method: 'post',
        responseType: action === 'download' ? 'blob' : 'json'
      });

      if (action === 'download') {
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = 'scheduled-report.xlsx';
        document.body.appendChild(a);
        a.click();
        a.remove();
      } else {
        openToast({
          type: 'success',
          label: t('Report emailed successfully!'),
          autoClose: 2000,
          theme: 'dark'
        });
      }
    } catch (err) {
      console.error('Error:', err);
    }
    setLoading(false);
  };

  const removeScheduledReportFromItems = (id: number): void => {
    const updatedReports: ScheduledReport[] = scheduledReports.filter((report: ScheduledReport) => report.id !== id);
    setScheduledReports(updatedReports);
  };

  const deleteScheduledReport = async (id: number): Promise<void> => {
    setLoading(true);
    const url: string = `${process.env.REACT_APP_API_URL}/api/report-schedules/${id}`;
    try {
      await axios.delete(url);
      removeScheduledReportFromItems(id);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  useEffect((): void => {
    void fetchScheduledReports(paginationModel);
  }, [updatedAccountId]);

  useEffect((): void => {
    setRowCountState(rowCount !== undefined ? rowCount : rowCountState);
  }, [rowCount]);

  const columns: GridColDef[] = [
    {
      field: 'actions',
      headerName: '',
      width: 75,
      renderCell: (cell: GridRenderCellParams) => {
        return (
          <div className="flex gap-2">
            <button
              type="button"
              onClick={(): void => {
                setExistingScheduledReport(cell.row);
                setIsEditScheduledReportModalOpen(true);
              }}
              className="transition text-custom-blue-normal hover:text-custom-blue-hover"
            >
              <Icon name="edit" />
            </button>
            <button
              className="text-custom-blue-normal hover:text-custom-blue-hover"
              onClick={() => {
                void runScheduledReport(cell.row, 'download');
              }}
            >
              <Icon name="play" />
            </button>
            <button
              className="text-custom-blue-normal hover:text-custom-blue-hover"
              onClick={() => {
                void runScheduledReport(cell.row, 'email');
              }}
            >
              <Icon name="envelope" />
            </button>
          </div>
        );
      }
    },
    {
      field: 'email',
      headerName: t('Email'),
      width: 300
    },
    {
      field: 'interval',
      headerName: t('Interval'),
      width: 175,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>{String(params.row.interval[0]).toUpperCase() + String(params.row.interval.slice(1))}</>
        );
      }
    },
    {
      field: 'day',
      headerName: t('Day'),
      width: 200,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>{String(params.row.day[0]).toUpperCase() + String(params.row.day.slice(1))}</>
        );
      }
    },
    {
      field: 'type',
      headerName: t('Type'),
      width: 200,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            {params.row.type.includes('-')
              ? String(
                params.row.type
                  .replace('-', ' ')
                  .split(' ')
                  .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(' ')
              )
              : String(params.row.type[0]).toUpperCase() + String(params.row.type.slice(1))}
          </>
        );
      }
    },
    {
      field: 'delete',
      headerName: '',
      width: 50,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <button
            className="text-custom-blue-normal hover:text-custom-blue-hover"
            onClick={() => {
              void deleteScheduledReport(params.row.id);
            }}
          >
            <Icon name="trash" />
          </button>
        );
      }
    }
  ];

  return (
    <>
      {loading && (
        <div
          className="p-4 rounded-xl absolute w-full h-full flex justify-center loading items-center bg-opacity-50 bg-black"
        >
          <CircularProgress />
        </div>
      )}
      <div className="bg-dark-3 p-4 rounded-xl">
        <div className="flex items-center justify-between mb-3">
          <div className="flex items-center gap-x-2 text-custom-gray-light">
            <Icon name="table-columns" />
            <span>{t('Reports')}</span>
          </div>
          <div className="flex items-center gap-x-3">
            <IconButton
              icon="play"
              onClick={(): void => {
                setIsRunReportModalOpen(true);
              }}
            />
            <IconButton
              icon="calendar"
              onClick={(): void => {
                setIsScheduledReportModalOpen(true);
              }}
            />
            {isRunReportModalOpen && (
              <ReportModal
                method="run"
                handleCloseModal={(): void => {
                  setIsRunReportModalOpen(false);
                }}
              />
            )}
            {isScheduledReportModalOpen && (
              <ScheduleReportModal
                method="schedule"
                handleCloseModal={(): void => {
                  setIsScheduledReportModalOpen(false);
                }}
                fetchScheduledReports={() => {
                  void fetchScheduledReports(paginationModel);
                }}
              />
            )}
            {isEditScheduledReportModalOpen && (
              <ScheduleReportModal
                method="edit"
                handleCloseModal={(): void => {
                  setIsEditScheduledReportModalOpen(false);
                  setExistingScheduledReport(null);
                }}
                existingScheduledReport={existingScheduledReport}
                fetchScheduledReports={(): void => {
                  void fetchScheduledReports(paginationModel);
                }}
              />
            )}
          </div>
        </div>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <DataGrid
            onStateChange={(state: GridInitialState): void => {
              handleDataGridStateChange('reports', state);
            }}
            initialState={appState.dataGridColumnState.value.reports ?? {}}
            disableDensitySelector={true}
            localeText={{
              noRowsLabel: t('No rows'),
              toolbarColumns: t('Columns'),
              toolbarColumnsLabel: t('Columns'),
              toolbarDensity: t('Density'),
              toolbarDensityLabel: t('Density'),
              toolbarDensityCompact: t('Compact'),
              toolbarDensityStandard: t('Standard'),
              toolbarDensityComfortable: t('Comfortable'),
              toolbarQuickFilterPlaceholder: `${t('Search')}...`,
              MuiTablePagination: {
                labelRowsPerPage: t('Rows per Page'),
                labelDisplayedRows: ({ from, to, count }) => `${from}-${to} ${t('of')} ${count}`
              }
            }}
            pagination
            rowCount={rowCountState}
            rows={scheduledReports}
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={(newModel) => {
              setPaginationModel(newModel);
              void fetchScheduledReports(newModel);
            }}
            columns={columns}
            disableColumnFilter
            disableColumnMenu
            disableRowSelectionOnClick
            density="compact"
            slotProps={{
              toolbar: {
                showQuickFilter: false,
                printOptions: { disableToolbarButton: true },
                csvOptions: { disableToolbarButton: true }
              }
            }}
            slots={{ toolbar: GridToolbar }}
            className="flex text-sm bg-dark-3 text-white px-4 py-2 rounded-lg gap-x-6 cursor-pointer"
            sx={{
              height: '50rem',
              '& .MuiDataGrid-row': {
                borderRadius: '80px',
                backgroundColor: '#242838',
                marginTop: '4px',
                borderBottom: 'none'
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none'
              },
              '& .MuiDataGrid-cell': {
                border: 'none'
              },
              '& .MuiDataGrid-columnHeaders': {
                borderRadius: '80px',
                backgroundColor: '#242838',
                borderBottom: 'none',
                marginBottom: '10px'
              },
              border: 0
            }}
          />
        </ThemeProvider>
      </div>
    </>
  );
};

export default withTranslation()(Reports);
