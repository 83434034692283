import React, { useContext, useEffect, useRef } from 'react';
import Icon from '../../../ui/shared/Icon';
import CalendarPickerModal from '../../../ui/shared/Modals/CalendarPicker.modal';
import { appState, handleDataGridStateChange } from '../../../index';
import { columns } from '../../../ui/shared/TransactionColumns';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { DataGrid, type GridInitialState, GridToolbar } from '@mui/x-data-grid';
import { AUTHORIZED_ROUTES } from '../../../enums/ROUTES';
import { Tooltip } from '@mui/material';
import useTransactions from '../../../hooks/useTransactions';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import { TransactionsContext } from '../../../contexts/transactionsContext';

const theme = createTheme({
  palette: {
    mode: 'dark'
  }
});
const DismissedTransactions: React.FC = () => {
  const gridRef = useRef<HTMLDivElement | null>(null);
  appState.currentRoute.value = AUTHORIZED_ROUTES.DISMISSED_TRANSACTIONS;
  const apiUrl = 'get-dismissed-transactions';
  const {
    loading,
    transformedData,
    rowCountState,
    headerField,
    paginationModel,
    setPaginationModel,
    reloadTransactions,
    removeDismissedTransaction,
    handleDatesSubmit
  } = useTransactions({ page: 0, pageSize: 50 }, apiUrl);
  const transactionColumns = columns(headerField, removeDismissedTransaction, loading);
  const { transactions, setTransactions } = useContext(TransactionsContext);

  useEffect(() => {
    setTransactions(transformedData);
  }, [transformedData]);

  const commentColumn = {
    field: 'comment',
    headerName: 'Comment',
    width: 200,
    renderCell: (params: any) => {
      return (
        <Tooltip
          title={params.value || ''}
          enterDelay={300}
          leaveDelay={200}
        >
          <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {params.value}
          </div>
        </Tooltip>
      );
    }
  };

  const fileColumn = {
    field: 'file',
    headerName: 'File',
    width: 200,
    renderCell: (params: any) => {
      return (
        <>
          {params.row.fileUrl && <a
            className="transition text-custom-blue-normal hover:text-custom-blue-hover outline-none"
            target="_blank"
            rel="noopener noreferrer"
            href={params.row.fileUrl}
          >
            View
          </a>}
        </>
      );
    }
  };

  const combinedColumns = [...transactionColumns, commentColumn, fileColumn];

  return (
    <>
      {loading && (
        <div
          className="p-4 rounded-xl absolute w-full h-full flex justify-center items-center loading bg-opacity-50 bg-black"
        >
          <CircularProgress/>
        </div>
      )}
      <div className="bg-dark-3 p-4 rounded-xl overflow-hidden">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-x-2 text-custom-gray-light">
            <Icon name="arrow-right-arrow-left"/>
            <span>{t('Dismissed Transactions')}</span>
          </div>
          <div className="flex items-center gap-x-3">
            <CalendarPickerModal
              handleDatesSubmit={handleDatesSubmit}
            />
          </div>
        </div>
        <div style={{ marginTop: '10px' }}>
          <ThemeProvider theme={theme}>
            <CssBaseline/>
            <div ref={gridRef}>
              <DataGrid
                onStateChange={(state: GridInitialState): void => {
                  handleDataGridStateChange('dismissedTransactions', state);
                }}
                initialState={appState.dataGridColumnState.value.dismissedTransactions ?? {}}
                disableDensitySelector={true}
                localeText={{
                  noRowsLabel: t('No rows'),
                  toolbarColumns: t('Columns'),
                  toolbarColumnsLabel: t('Columns'),
                  toolbarDensity: t('Density'),
                  toolbarDensityLabel: t('Density'),
                  toolbarDensityCompact: t('Compact'),
                  toolbarDensityStandard: t('Standard'),
                  toolbarDensityComfortable: t('Comfortable'),
                  toolbarQuickFilterPlaceholder: `${t('Search')}...`,
                  MuiTablePagination: {
                    labelRowsPerPage: t('Rows per Page'),
                    labelDisplayedRows: ({ from, to, count }) => `${from}-${to} ${t('of')} ${count}`
                  }
                }}
                pagination
                rowCount={rowCountState}
                rows={transactions}
                paginationMode="server"
                paginationModel={paginationModel}
                onPaginationModelChange={(newModel) => {
                  setPaginationModel(newModel);
                }}
                columns={combinedColumns}
                disableColumnFilter
                disableColumnMenu
                disableRowSelectionOnClick
                density="compact"
                slotProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true }
                  }
                }}
                slots={{ toolbar: GridToolbar }}
                className="flex text-sm bg-dark-3 text-white px-4 py-2 rounded-lg gap-x-6 cursor-pointer"
                sx={{
                  height: '50rem',
                  '& .MuiDataGrid-row': {
                    borderRadius: '80px',
                    backgroundColor: '#242838',
                    marginTop: '4px',
                    borderBottom: 'none'
                  },
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none'
                  },
                  '& .MuiDataGrid-cell': {
                    border: 'none'
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    borderRadius: '80px',
                    backgroundColor: '#242838',
                    borderBottom: 'none',
                    marginBottom: '10px'
                  },
                  border: 0
                }}
              />
            </div>
          </ThemeProvider>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(DismissedTransactions);
