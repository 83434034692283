import React, { useState, useEffect, useCallback, type Dispatch, type SetStateAction, useRef } from 'react';
import { CircularProgress } from '@mui/material';
import { gasStationIcon, vehicleIcon } from '../../../../helpers/CustomerAssets';
import { useLoadScript } from '@react-google-maps/api';
import type { Libraries } from '@react-google-maps/api';
import MapToggle from './MapToggle';

interface Props {
  gasStationX: number;
  setGasStationX: Dispatch<SetStateAction<any>>;
  gasStationY: number;
  setGasStationY: Dispatch<SetStateAction<any>>;
  vehicleLocation: {
    latitude: number,
    longitude: number,
  };
  clickable: boolean | undefined;
}

const libraries: Libraries = ['marker'];

const TransactionLocation: React.FC<Props> = ({
  gasStationX,
  setGasStationX,
  gasStationY,
  setGasStationY,
  vehicleLocation,
  clickable
}) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);

  const [gasStation, setGasStation] = useState({ lat: Number(gasStationY), lng: Number(gasStationX) });
  const [vehicle, setVehicle] = useState<google.maps.LatLngLiteral | null>(null);
  const clickableRef = useRef(clickable);
  const gasStationMarkerRef = useRef<google.maps.marker.AdvancedMarkerElement | null>(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
    libraries,
    id: 'google-maps-script'
  });

  useEffect(() => {
    if (vehicleLocation) {
      setVehicle({
        lat: Number(vehicleLocation.latitude),
        lng: Number(vehicleLocation.longitude)
      });
    }
  }, [vehicleLocation]);

  useEffect(() => {
    clickableRef.current = clickable;
  }, [clickable]);

  const onLoad = useCallback(
    (mapInstance: google.maps.Map) => {
      const bounds = new google.maps.LatLngBounds();

      if (vehicleLocation.latitude && vehicleLocation.longitude) {
        bounds.extend({
          lat: Number(vehicleLocation.latitude),
          lng: Number(vehicleLocation.longitude)
        });
        bounds.extend(gasStation);
      } else {
        bounds.extend(gasStation);
      }

      // Expand the bounds slightly to add padding
      const padFactor = 0.001;
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();

      bounds.extend({
        lat: ne.lat() + padFactor,
        lng: ne.lng() + padFactor
      });
      bounds.extend({
        lat: sw.lat() - padFactor,
        lng: sw.lng() - padFactor
      });

      mapInstance.fitBounds(bounds);

      // Set map options
      mapInstance.setOptions({
        gestureHandling: 'greedy'
      });

      setMap(mapInstance);
    },
    [gasStation, vehicleLocation.latitude, vehicleLocation.longitude]
  );

  const createAdvancedMarker = useCallback(
    (
      position: google.maps.LatLngLiteral,
      map: google.maps.Map,
      iconUrl: string,
      key?: string,
      zIndex?: number
    ): google.maps.marker.AdvancedMarkerElement => {
      const element = document.createElement('div');
      element.style.width = '75px';
      element.style.height = '75px';
      element.style.backgroundImage = `url(${iconUrl})`;
      element.style.backgroundSize = 'cover';

      if (key) {
        element.setAttribute('key', key);
      }

      return new google.maps.marker.AdvancedMarkerElement({
        position,
        map,
        content: element,
        zIndex
      });
    },
    []
  );

  const addMarkerOnClick = (mapInstance: google.maps.Map): void => {
    google.maps.event.addListener(mapInstance, 'click', (event: google.maps.MapMouseEvent) => {
      if (event.latLng && clickableRef.current) {
        const newLocation = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng()
        };

        if (gasStationMarkerRef.current) {
          gasStationMarkerRef.current.map = null; // This removes the marker from the map
        }

        const newMarker = createAdvancedMarker(
          newLocation,
          mapInstance,
          gasStationIcon.value,
          `${gasStation.lat}_${gasStation.lng}`,
          101
        );

        gasStationMarkerRef.current = newMarker;

        setGasStation(newLocation);
        setGasStationX(newLocation.lng);
        setGasStationY(newLocation.lat);
      }
    });
  };

  return (
    <>
      {!isLoaded
        ? (
          <div className="text-center">
            <CircularProgress />
          </div>
          )
        : (
          <div style={{ position: 'relative', height: '100%', width: '100%' }}>
            <div
              id="map"
              style={{ height: '100%', width: '100%' }}
              ref={(mapContainer) => {
                if (mapContainer && !map) {
                  const mapInstance = new google.maps.Map(mapContainer, {
                    center: gasStation,
                    disableDefaultUI: true,
                    mapId: '3e2d3f5dd205f1d9'
                  });
                  onLoad(mapInstance);
                  addMarkerOnClick(mapInstance);
                  const gasStationMarker = createAdvancedMarker(
                    gasStation,
                    mapInstance,
                    gasStationIcon.value,
                    `${gasStation.lat}_${gasStation.lng}`,
                    101
                  );
                  gasStationMarkerRef.current = gasStationMarker;
                  if (vehicle) {
                    createAdvancedMarker(
                      vehicle,
                      mapInstance,
                      vehicleIcon.value,
                      'vehicle_marker'
                    );
                  }
                }
              }}
            />
            {/* Overlay the custom map toggle */}
            <MapToggle map={map} />
          </div>
          )}
    </>
  );
};

export default TransactionLocation;
