import { appState, useSignal } from '../index';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { iconTypeMapping, isValidTransactionType } from '../helpers/types';
import { startCase } from 'lodash';
import { openToast } from '../helpers/toast';
import { t } from 'i18next';
import { fetchUniqueAccountToFcp } from '../helpers/api';
import { type AccountToFcp } from '../types/fcp.d';
import {
  type TransformedTransaction,
  type TransactionDiscrepancyResource,
  type TransactionDiscrepancyGroupResource,
  type NestedTransactionDiscrepancyResource,
  type TransformedTransactionGroup
} from '../types/transactions.d';

interface PaginationModel {
  page: number;
  pageSize: number;
}

interface DateFilterType {
  fromDate: number | null;
  toDate: number | null;
}

export const getTransactionGroupVehicleName = (transactions: NestedTransactionDiscrepancyResource[] | undefined): string[] => {
  const uniqueVehicleNames = new Set<string>();
  transactions?.forEach((transaction: NestedTransactionDiscrepancyResource) => {
    if (transaction.vehicleName) {
      uniqueVehicleNames.add(transaction.vehicleName);
    }
  });
  return Array.from(uniqueVehicleNames);
};

export const transformTransactionGroup = (transactionGroup: TransactionDiscrepancyGroupResource, transaction: TransactionDiscrepancyResource): TransformedTransactionGroup => {
  return {
    id: transactionGroup.id,
    type: 'group',
    kind: 'transactionGroup',
    numUnits: (parseFloat(transactionGroup.totalUnits)).toFixed(2),
    totalCost: '$' + (parseFloat(transactionGroup.totalCost)).toFixed(2),
    date: transaction.convertedDatetime.split(', ')[0],
    amountPutIn: transactionGroup.amountPutIn,
    uniqueId: transactionGroup.transactions[0].uniqueIdentifier,
    vehicleName: getTransactionGroupVehicleName(transactionGroup.transactions).length > 1 ? 'Multiple' : transactionGroup.transactions[0].vehicleName ?? 'N/A',
    vehicleId: transaction.vehicleId,
    driverName: transactionGroup.driverNames.length > 1 ? 'Multiple' : transactionGroup.driverNames.length === 1 ? transactionGroup.driverNames[0] : 'None',
    annotationId: transaction.approvalAnnotation?.id ?? null,
    comment: transaction.approvalAnnotation?.comment ?? null,
    fileUrl: transaction.approvalAnnotation?.fileUrl ?? null,
    icon: iconTypeMapping.group,
    transactions: transactionGroup.transactions,
    vehicleGroups: transaction?.geotabVehicleGroupNames ?? null,
    fuelLevelDifference: transactionGroup.fuelLevelDifference
  };
};

export const transformTransaction = (transaction: TransactionDiscrepancyResource): TransformedTransaction => {
  return {
    id: transaction.id,
    transactionGroupId: transaction.transactionGroup?.id ?? null,
    type: isValidTransactionType(transaction.type) ? transaction.type : 'none',
    kind: 'transaction',
    numUnits: (parseFloat(transaction.numUnits)).toFixed(2),
    totalCost: '$' + (parseFloat(transaction.totalCost)).toFixed(2),
    costCenter: transaction.costCenter ?? 0,
    prn: transaction.prn ?? 0,
    date: transaction.convertedDatetime.split(', ')[0],
    datetime: transaction.datetime,
    uniqueId: transaction.uniqueIdentifier,
    vehicleName: transaction.vehicleName,
    vehicleId: transaction.vehicleId,
    driverName: `${transaction?.driverFname}` + ' ' + `${transaction?.driverLname}`,
    convertedDatetime: transaction.convertedDatetime,
    annotationId: transaction.approvalAnnotation?.id ?? null,
    comment: transaction.approvalAnnotation?.comment ?? null,
    fileUrl: transaction.approvalAnnotation?.fileUrl ?? null,
    icon: iconTypeMapping[transaction?.subtypes?.length > 0 ? 'multiple' : transaction.type ?? 'none'],
    subtypes: transaction?.subtypes,
    vehicleGroups: transaction?.geotabVehicleGroupNames ?? null,
    costPerUnit: transaction.costPerUnit,
    fillingTime: transaction.fillingTime,
    preFillingFuelPercent: transaction.preFillingFuelPercent,
    fuelLevelDifference: transaction.fuelLevelDifference,
    distance: transaction.distance,
    fuelType: transaction.fuelType,
    gasStationId: transaction.gasStationId,
    vehicleLatitude: transaction.vehicleLatitude,
    vehicleLongitude: transaction.vehicleLongitude,
    timestamp: transaction.timestamp,
    originalTimestamp: transaction.originalTimestamp
  };
};

export function getTransformedTransactions (transactions: TransactionDiscrepancyResource[]): Array<TransformedTransaction | TransformedTransactionGroup> {
  const encounteredGroupIds = new Set<number>();
  const unifiedData: Array<TransformedTransaction | TransformedTransactionGroup> = [];
  transactions.forEach((transaction: TransactionDiscrepancyResource) => {
    if (transaction.transactionGroup) {
      const groupId = transaction.transactionGroup.id;
      if (!encounteredGroupIds.has(groupId)) {
        transaction.transactionGroup.transactions.forEach((groupedTransaction: NestedTransactionDiscrepancyResource) => {
          if (groupedTransaction.id === transaction.id) {
            groupedTransaction.type = transaction.type;
          }
        });
        encounteredGroupIds.add(groupId);
        const transformedGroup = transformTransactionGroup(transaction.transactionGroup, transaction);
        if (transformedGroup) {
          unifiedData.push(transformedGroup);
        }
      }
    } else {
      unifiedData.push(transformTransaction(transaction));
    }
  });

  // Sort unifiedData based on date
  unifiedData.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  return unifiedData;
}

const useTransactions = (initialPaginationModel: PaginationModel, apiUrl: string, transactionIds: number[] | null = null): any => {
  const updatedAccountId = useSignal(appState.accountId);
  const [loading, setLoading] = useState(false);
  const [transformedData, setTransformedData] = useState<Array<TransformedTransaction | TransformedTransactionGroup>>([]);
  const [rowCountState, setRowCountState] = useState(0);
  const [headerField, setHeaderField] = useState('Details');
  const [paginationModel, setPaginationModel] = useState(initialPaginationModel);
  const [dateFilter, setDateFilter] = useState<DateFilterType>({ fromDate: null, toDate: null });

  const updatePaginationModel = (newModel: PaginationModel): void => {
    if (newModel.pageSize !== paginationModel.pageSize) {
      newModel.page = 0;
    }
    setPaginationModel(newModel);
  };

  const getHeaderField = (field: string): string => {
    return startCase(field.replace('_', ' '));
  };
  const stableGetHeaderField = useCallback(getHeaderField, []);
  // Function to fetch transactions from the API
  const fetchTransactions = useCallback(async () => {
    setLoading(true);
    const transactionsUrl = `${process.env.REACT_APP_API_URL}/api/${apiUrl}`;
    try {
      const { page, pageSize } = paginationModel;
      const btParams = {
        page: page + 1,
        pageSize,
        dateFilter: {
          fromDate: dateFilter.fromDate ? dateFilter.fromDate / 1000 : null,
          toDate: dateFilter.toDate ? dateFilter.toDate / 1000 : null
        },
        transactionIds
      };

      const uniqueFuelCardProviders: AccountToFcp[] | null = await fetchUniqueAccountToFcp(appState.accountId.value);
      if (uniqueFuelCardProviders) {
        if (uniqueFuelCardProviders?.length > 1) {
          setHeaderField(getHeaderField('Unique Field'));
        } else {
          setHeaderField(getHeaderField(uniqueFuelCardProviders[0]?.uniqueField));
        }
      }

      if (dateFilter.fromDate !== null && dateFilter.toDate !== null) {
        await fetchTransactionsWithDateFilter(dateFilter.fromDate, dateFilter.toDate);
        return;
      }
      const response = await axios.post(transactionsUrl, btParams);

      if (response.data.data) {
        setTransformedData(getTransformedTransactions(response.data.data));
        setRowCountState(response.data.meta.total);
      } else {
        setTransformedData([]);
        setRowCountState(0);
      }
    } catch (error) {
      console.error('Error fetching transactions:', error);
      setTransformedData([]);
      setRowCountState(0);
    }
    setLoading(false);
  }, [appState.accountId.value, paginationModel, dateFilter, apiUrl, stableGetHeaderField]);

  useEffect(() => {
    void fetchTransactions();
  }, [updatedAccountId, paginationModel]);

  const reloadTransactions = useCallback(() => {
    void fetchTransactions();
  }, [fetchTransactions]);

  const removeDismissedTransaction = useCallback((id: number): void => {
    setTransformedData((prevData) => prevData.filter((transaction: TransformedTransaction | TransformedTransactionGroup) => transaction.id !== id));
  }, []);

  const fetchTransactionsWithDateFilter = async (fromDate: any, toDate: any): Promise<void> => {
    setLoading(true);
    const url = `${process.env.REACT_APP_API_URL}/api/${apiUrl}`;

    try {
      const { page, pageSize } = paginationModel;
      const btParams = {
        dateFilter: {
          fromDate: fromDate / 1000, // Assuming fromDate is already a timestamp
          toDate: toDate / 1000 // Assuming toDate is already a timestamp
        },
        page: page + 1,
        pageSize
      };

      const response = await axios.post(url, btParams);
      if (response.data.data.length) {
        setTransformedData(getTransformedTransactions(response.data.data));
        setRowCountState(response.data.meta.total);
      } else {
        setTransformedData([]);
        setRowCountState(response.data.meta.total);
      }
    } catch (error) {
      console.error('Error fetching transactions:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDatesSubmit = async (values: any): Promise<void> => {
    if (values.from && values.to) {
      const fromDate = new Date(values.from.year, values.from.month - 1, values.from.day).getTime();
      const toDate = new Date(values.to.year, values.to.month - 1, values.to.day, 23, 59, 59).getTime();
      setDateFilter({ fromDate, toDate });
      await fetchTransactionsWithDateFilter(fromDate, toDate);
      setPaginationModel({ ...paginationModel, page: 0 });
    } else {
      openToast({ type: 'error', label: t('Invalid date selection'), autoClose: 2000, theme: 'dark' });
    }
  };

  return {
    loading,
    transformedData,
    rowCountState,
    headerField,
    paginationModel,
    setPaginationModel: updatePaginationModel,
    reloadTransactions,
    removeDismissedTransaction,
    handleDatesSubmit,
    fetchTransactions
  };
};

export default useTransactions;
