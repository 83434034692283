import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import { Formik } from 'formik';
import axios from 'axios';
import Menu, { MenuItem } from 'rc-menu';
import Modal from '../../Modal';
import Icon from '../../Icon';
import Input from '../../Formik/Input';
import DropdownV2 from '../../DropdownV2/DropdownV2';
import { openToast } from '../../../../helpers/toast';
import { appState } from '../../../..';
import { AlertType, alertTypes } from '../../../../helpers/ReportHelpers/ReportAlerts';
import { type Report } from '../../../../types/reports.d';
import { type TransactionAlerts, type TransactionAlertName } from '../../../../helpers/types';
import { CircularProgress } from '@mui/material';

interface Props {
  fetchScheduledReports?: () => void;
  method: 'edit' | 'schedule';
  existingScheduledReport?: Report | null;
  handleCloseModal: () => void;
}

const ScheduleReportHeader: React.FC = () => (
  <div className="flex items-center gap-x-2">
    <Icon name="calendar" />
    <p>{t('Schedule Report')}</p>
  </div>
);

interface User {
  id: string;
  email: string;
}

interface TransformedUser {
  id: string;
  email: string;
}

const transformData = (users: User[]): TransformedUser[] => {
  return users
    .filter(user => user.email)
    .map(user => ({
      id: user.id,
      email: user.email
    }));
};

const ScheduleReportModal: React.FC<Props> = ({
  fetchScheduledReports,
  handleCloseModal,
  method,
  existingScheduledReport = null
}) => {
  // State for the user dropdown
  const [users, setUsers] = useState<TransformedUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<TransformedUser>({ id: '', email: '' });
  const [existingReport] = useState<Report | null>(existingScheduledReport);

  // State for the report scheduling
  const [disabled, setDisabled] = useState<boolean>(false);
  const [reportType, setReportType] = useState<string>(
    existingReport
      ? (existingReport.type === 'vehicle'
          ? t('Vehicles')
          : existingReport.type === 'vehicle-group'
            ? t('Vehicle Groups')
            : existingReport.type === 'fuel-card'
              ? t('Fuel Cards')
              : existingReport.type === 'driver'
                ? t('Drivers')
                : t('Vehicles'))
      : t('Vehicles')
  );
  const [reportTypeString, setReportTypeString] = useState<string>(existingReport ? existingReport.type : 'vehicle');
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [scheduledDay, setScheduledDay] = useState<string>(
    existingReport
      ? existingReport.day.charAt(0).toUpperCase() + existingReport.day.slice(1)
      : 'Monday'
  );
  const [interval, setInterval] = useState<string>(
    existingReport
      ? existingReport.interval.charAt(0).toUpperCase() + existingReport.interval.slice(1)
      : 'Weekly'
  );
  const [alerts, setAlerts] = useState<TransactionAlerts>(
    existingReport?.alerts ?? {
      no_vehicle_location: false,
      location_mismatch: false,
      fuel_level_mismatch: false,
      fuel_type_mismatch: false,
      filling_time_alert: false,
      filling_percent_alert: false,
      tank_capacity_mismatch: false,
      out_of_state_alert: false
    }
  );
  const showExtraAlerts = localStorage.getItem('accountName') === 'CDOT';

  // Fetch the list of users for the dropdown.
  const fetchUsers = async (): Promise<void> => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/api/users-minimized/${appState.accountId.value}`;
      const response = await axios.get(url);
      const transformedUsers = transformData(response.data.data);
      setUsers(transformedUsers);
      if (existingReport) {
        const userToSelect = transformedUsers.find(user => user.email === existingReport.email);
        setSelectedUser(userToSelect ?? { id: '', email: '' });
      }
    } catch (error) {
      console.error('Error fetching users data:', error);
    }
  };

  // Create a scheduled report
  const createScheduledReport = async (values: any): Promise<void> => {
    const url = `${process.env.REACT_APP_API_URL}/api/report-schedules`;
    const params = {
      account_id: appState.accountId.value,
      user_id: selectedUser.id,
      day: scheduledDay.toLowerCase(),
      time: '00:00',
      timezone: 'UTC',
      interval: interval.toLowerCase(),
      alerts: JSON.stringify(alerts),
      type: reportTypeString
    };
    try {
      await axios.post(url, params);
      openToast({ type: 'success', label: t('Report scheduled'), autoClose: 2000, theme: 'dark' });
    } catch (err) {
      console.error(err);
      setLoading(false);
      openToast({ type: 'error', label: t('Error scheduling report'), autoClose: 2000, theme: 'dark' });
    }
  };

  // Error handling for scheduling
  function handleScheduleReportError (message: string): void {
    setErrorMessage(message);
    setShowError(true);
    setLoading(false);
    setDisabled(false);
  }

  // Schedule report handler
  const scheduleReport = async (values: any, reloadReports: () => void): Promise<void> => {
    setLoading(true);
    setShowError(false);
    if (!selectedUser?.id) {
      handleScheduleReportError(t('Must select a user'));
      return;
    }
    let valid = false;
    for (const alert in alerts) {
      if (alerts[alert as TransactionAlertName]) valid = true;
    }
    if (!valid) {
      handleScheduleReportError(t('No alerts selected'));
      return;
    }
    try {
      await createScheduledReport(values);
      handleCloseModal();
      setLoading(false);
      reloadReports();
    } catch (err) {
      console.error(err);
      setDisabled(false);
    }
  };

  // Edit report handler
  const editScheduledReport = async (values: any, reloadReports: () => void): Promise<void> => {
    setLoading(true);
    setShowError(false);
    if (!selectedUser?.id) {
      handleScheduleReportError(t('Must select a user'));
      return;
    }
    let valid = false;
    for (const alert in alerts) {
      if (alerts[alert as TransactionAlertName]) valid = true;
    }
    if (!valid) {
      handleScheduleReportError(t('No alerts selected'));
      return;
    }
    const url = `${process.env.REACT_APP_API_URL}/api/report-schedules/${existingReport?.id}`;
    const params = {
      account_id: appState.accountId.value,
      day: scheduledDay.toLowerCase(),
      interval: interval.toLowerCase(),
      alerts: JSON.stringify(alerts),
      time: '00:00',
      timezone: 'UTC',
      type: reportTypeString
    };
    try {
      await axios.put(url, params);
      handleCloseModal();
      setLoading(false);
      reloadReports();
      openToast({ type: 'success', label: t('Report updated'), autoClose: 2000, theme: 'dark' });
    } catch (err) {
      console.error(err);
      openToast({ type: 'error', label: t('Error updating report'), autoClose: 2000, theme: 'dark' });
      setDisabled(false);
    }
  };

  // Toggle alert types
  const addAlert = (checked: boolean, alert: string): void => {
    setAlerts({
      ...alerts,
      [alert]: checked
    });
  };

  useEffect(() => {
    void fetchUsers();
  }, []);

  // Handler for when the category selection changes.
  const handleReportTypeChange = (value: string): void => {
    setReportType(value);
    if (value === t('Vehicles')) {
      setReportTypeString('vehicle');
    } else if (value === t('Vehicle Groups')) {
      setReportTypeString('vehicle-group');
    } else if (value === t('Fuel Cards')) {
      setReportTypeString('fuel-card');
    } else if (value === t('Drivers')) {
      setReportTypeString('driver');
    }
  };

  return (
    <Modal
      header={<ScheduleReportHeader />}
      show={true}
      onHide={() => {
        handleCloseModal();
        setTimeout(() => {
          setDisabled(false);
        }, 500);
      }}
      maxWidth={600}
    >
      {loading && (
        <div
          className="w-full h-full absolute inset-0 flex justify-center items-center z-[1000] bg-opacity-20 bg-black"
        >
          <CircularProgress />
        </div>
      )}
      <Formik
        enableReinitialize
        initialValues={{
          id: existingReport?.id ?? ''
        }}
        onSubmit={(values, { setSubmitting }) => {
          setDisabled(true);
          if (method === 'edit') {
            if (fetchScheduledReports) {
              void editScheduledReport(values, fetchScheduledReports);
            }
          } else if (method === 'schedule') {
            if (fetchScheduledReports) {
              void scheduleReport(values, fetchScheduledReports);
            }
          }
          setSubmitting(false);
        }}
      >
        {(props: any) => (
          <form
            onSubmit={props.handleSubmit}
            className="mt-4 flex flex-col gap-y-6"
          >
            {/* Category selection */}
            <Input
              name="transactionsFrom"
              type="select"
              label="Include transactions from"
              defaultValue={reportType}
              options={[t('Vehicles'), t('Vehicle Groups'), t('Fuel Cards'), t('Drivers')]}
              onChange={handleReportTypeChange}
              handleChange={() => { }}
            />

            {/* User dropdown */}
            <div className="flex flex-col gap-y-2">
              <p className="text-xs">User</p>
              <DropdownV2
                key="user-dropdown"
                menu={
                  <Menu key="user-menu">
                    {users.map((user: TransformedUser, index: number) => (
                      <MenuItem
                        key={+user.id + index}
                        className="cursor-pointer"
                        onClick={() => { setSelectedUser(user); }}
                      >
                        {user.email}
                      </MenuItem>
                    ))}
                  </Menu>
                }
              >
                <button
                  type="button"
                  className="transition w-full bg-dark-2 focus:bg-dark-4 outline-none px-3 rounded-full h-[36px] text-xs flex items-center justify-between"
                >
                  <span className="truncate">
                    {selectedUser?.id ? selectedUser.email : 'Select a user'}
                  </span>
                  <Icon name="caret-down" />
                </button>
              </DropdownV2>
            </div>

            {/* Frequency selection */}
            <div className="flex flex-col gap-y-2">
              <p className="text-xs">Frequency</p>
              <DropdownV2
                key="schedule-report-interval-dropdown"
                menu={
                  <Menu key="schedule-report-interval-menu">
                    {['Weekly', 'Biweekly', 'Monthly'].map((item: string, index: number) => (
                      <MenuItem
                        key={`${item}-${index}`}
                        className="cursor-pointer"
                        onClick={() => {
                          setInterval(item);
                          // For weekly/biweekly use days of the week; otherwise use a day-of-month.
                          if (item === 'Weekly' || item === 'Biweekly') {
                            setScheduledDay('Monday');
                          } else {
                            setScheduledDay('01');
                          }
                        }}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </Menu>
                }
              >
                <button
                  type="button"
                  className="transition w-full bg-dark-2 focus:bg-dark-4 outline-none px-3 rounded-full h-[36px] text-xs flex items-center justify-between"
                >
                  <span className="truncate">{interval}</span>
                  <Icon name="caret-down" />
                </button>
              </DropdownV2>
            </div>

            {/* Day selection */}
            {interval === 'Weekly' || interval === 'Biweekly'
              ? (
                <div className="flex flex-col gap-y-2">
                  <p className="text-xs">Day</p>
                  <DropdownV2
                    key="schedule-report-day-dropdown"
                    menu={
                      <Menu key="schedule-report-day-menu">
                        {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(
                          (item: string, index: number) => (
                            <MenuItem
                              key={`${item}-${index}`}
                              className="cursor-pointer"
                              onClick={() => { setScheduledDay(item); }}
                            >
                              {item}
                            </MenuItem>
                          )
                        )}
                      </Menu>
                    }
                  >
                    <button
                      type="button"
                      className="transition w-full bg-dark-2 focus:bg-dark-4 outline-none px-3 rounded-full h-[36px] text-xs flex items-center justify-between"
                    >
                      <span className="truncate">{scheduledDay}</span>
                      <Icon name="caret-down" />
                    </button>
                  </DropdownV2>
                </div>
                )
              : (
                <div className="flex flex-col gap-y-2">
                  <p className="text-xs">Day</p>
                  <DropdownV2
                    key="schedule-report-day-dropdown"
                    menu={
                      <Menu
                        key="schedule-report-day-menu"
                        style={{ maxHeight: '300px', overflowY: 'auto' }}
                      >
                        {[
                          '01',
                          '02',
                          '03',
                          '04',
                          '05',
                          '06',
                          '07',
                          '08',
                          '09',
                          '10',
                          '11',
                          '12',
                          '13',
                          '14',
                          '15',
                          '16',
                          '17',
                          '18',
                          '19',
                          '20',
                          '21',
                          '22',
                          '23',
                          '24',
                          '25',
                          '26',
                          '27',
                          '28'
                        ].map((item: string, index: number) => (
                          <MenuItem
                            key={+item + index}
                            className="cursor-pointer"
                            onClick={() => { setScheduledDay(item); }}
                          >
                            {item}
                          </MenuItem>
                        ))}
                      </Menu>
                    }
                  >
                    <button
                      type="button"
                      className="transition w-full bg-dark-2 focus:bg-dark-4 outline-none px-3 rounded-full h-[36px] text-xs flex items-center justify-between"
                    >
                      <span className="truncate">{scheduledDay}</span>
                      <Icon name="caret-down" />
                    </button>
                  </DropdownV2>
                </div>
                )}

            {/* Alert Types */}
            <div className="flex flex-col gap-y-2">
              <p className="font-bold text-sm">Alert Types</p>
              {alertTypes.map((alert) => (
                <AlertType
                  key={alert.key}
                  alertKey={alert.key}
                  label={alert.label}
                  checked={alerts[alert.key as keyof TransactionAlerts]}
                  addAlert={addAlert}
                />
              ))}
              {showExtraAlerts && (
                <>
                  <AlertType
                    alertKey="tank_capacity_mismatch"
                    label="Tank Capacity Mismatch"
                    checked={alerts.tank_capacity_mismatch}
                    addAlert={addAlert}
                  />
                  <AlertType
                    alertKey="out_of_state_alert"
                    label="Out of State Alert"
                    checked={alerts.out_of_state_alert}
                    addAlert={addAlert}
                  />
                </>
              )}
            </div>

            {/* Submit Button & Error Message */}
            <div className="flex items-end gap-4">
              <button
                disabled={disabled}
                className="transition text-sm h-[28px] px-8 rounded-full bg-custom-blue-normal hover:bg-custom-blue-hover text-white"
                type="submit"
              >
                {t('Submit')}
              </button>
              {showError && <p className="text-error-3">{errorMessage}</p>}
            </div>
          </form>
        )}
      </Formik>
    </Modal >
  );
};

export default withTranslation()(ScheduleReportModal);
