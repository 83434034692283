import React, { useEffect, useState } from 'react';
import IconButton from '../../../ui/shared/IconButton';
import Modal from '../../../ui/shared/Modal';
import Icon from '../../../ui/shared/Icon';
import { Formik } from 'formik';
import Input from '../../../ui/shared/Formik/Input';
import Button from '../../../ui/shared/Button';
import { t } from 'i18next';
import axios, { AxiosError } from 'axios';
import { getParentAuthHeader } from '../../../helpers/api';
import { appState } from '../../..';
import { openToast } from '../../../helpers/toast';
import { CircularProgress } from '@mui/material';
import * as yup from 'yup';
import FuelCardSettingsTooltip from './FuelCardSettingsTooltip';
import {
  type AccountToFcp,
  type FuelCardProvider,
  type DefaultPairing
} from '../../../types/fcp.d';
import Switch from '../../../ui/shared/Switch';

// Yup schema – every field is required.
const defaultPairingsSchema = yup.object().shape({
  name: yup.string().required('Required'),
  fcp_field: yup.string().required('Required'),
  geotab_field: yup.string().required('Required')
});

interface FuelCardSettingsProps {
  accountToFcpData: AccountToFcp[];
}

const FuelCardSettingsModal: React.FC<FuelCardSettingsProps> = ({ accountToFcpData }) => {
  const [isShown, setIsShown] = useState<boolean>(false);
  const [accountToFcpId, setAccountToFcpId] = useState<number | null>(null);
  const [pairs, setPairs] = useState<DefaultPairing[]>([]);
  const [selectedPair, setSelectedPair] = useState<DefaultPairing | null>(null);
  const [loading, setLoading] = useState(false);
  const [accountToFcp, setAccountToFcp] = useState<AccountToFcp | null>(null);
  const [fcpName, setFcpName] = useState<string>('');
  const [fcpFieldOptions, setFcpFieldOptions] = useState<any[]>([]);
  const [geotabFieldOptions, setGeotabFieldOptions] = useState<any[]>([]);
  // Store initial form values (will be updated when pairing data is fetched)
  const [initialFormValues, setInitialFormValues] = useState({
    name: '',
    fcp_field: '',
    geotab_field: '',
    wildcard_search: false
  });

  // Field option lists
  const vehicleFcpFields = [
    { key: t('VIN'), value: 'vin' },
    { key: t('Card Pin'), value: 'card_pin' },
    { key: t('Name On Card'), value: 'name_on_card' },
    { key: t('Unit Card Id'), value: 'unique_card_id' },
    { key: t('Unit Number'), value: 'unit_number' },
    { key: t('Vehicle Number'), value: 'vehicle_number' },
    { key: t('Card Number'), value: 'card_number' }
  ];
  const vehicleGeotabFields = [
    { key: t('VIN'), value: 'vehicleIdentificationNumber' },
    { key: t('Vehicle Name'), value: 'name' },
    { key: t('Comments'), value: 'comment' },
    { key: t('License Plate'), value: 'licensePlate' },
    { key: t('Serial Number'), value: 'serialNumber' }
  ];
  const driverFcpFields = [
    { key: t('Card Pin'), value: 'card_pin' },
    { key: t('Name On Card'), value: 'name_on_card' },
    { key: t('Unit Card Id'), value: 'unique_card_id' },
    { key: t('Driver ID'), value: 'driver_id' },
    { key: t('Unit Number'), value: 'unit_number' },
    { key: t('Card Number'), value: 'card_number' }
  ];
  const driverGeotabFields = [
    { key: t('Comments'), value: 'comment' },
    { key: t('Employee Number'), value: 'employeeNo' },
    { key: t('License Number'), value: 'licenseNumber' },
    { key: t('Name'), value: 'name' },
    { key: t('Phone Number'), value: 'phoneNumber' },
    { key: t('Designation'), value: 'designation' }
  ];

  const getPairs = async (): Promise<void> => {
    setLoading(true);
    const url = `${process.env.REACT_APP_API_URL}/api/fuel-cards/default-pairing/${appState.accountId.value}`;
    try {
      const response = await axios.get(url);
      setPairs(response.data.data || []);
      if (response.data.data.length > 0) {
        const initialPair = response.data.data[0];
        setSelectedPair(initialPair);
        const acct = accountToFcpData[0];
        if (acct) {
          setAccountToFcp(acct);
          setAccountToFcpId(acct.accountToFcpId);
          const name = acct.name ?? '';
          setFcpName(name);

          setInitialFormValues({
            name,
            fcp_field: initialPair.fcp_field,
            geotab_field: initialPair.geotab_field,
            wildcard_search: !!initialPair.wildcard_search
          });

          // Set field options based on preferred match
          if (acct.preferredMatch === 'vehicle') {
            setFcpFieldOptions(vehicleFcpFields);
            setGeotabFieldOptions(vehicleGeotabFields);
          } else if (acct.preferredMatch === 'driver') {
            setFcpFieldOptions(driverFcpFields);
            setGeotabFieldOptions(driverGeotabFields);
          }
        }
      }
    } catch (err) {
      console.error(err);
      openToast({
        type: 'error',
        label: t('Error getting default pairings'),
        autoClose: 2000,
        theme: 'dark'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSelectPair = (pair: DefaultPairing | null): void => {
    setSelectedPair(pair);
  };

  const handleSubmit = async (settingsSubmitted: any): Promise<boolean> => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/api/fuel-cards/default-pairing/${appState.accountId}`;
      const params = {
        account_to_fcp_id: settingsSubmitted.account_to_fcp_id,
        fcp_field: settingsSubmitted.fcp_field,
        geotab_field: settingsSubmitted.geotab_field,
        wildcard_search: settingsSubmitted.wildcard_search
      };
      const headers = getParentAuthHeader();
      await axios.put(url, params, headers ? { headers } : undefined);
      openToast({
        type: 'success',
        label: t('Default Pairing Updated'),
        autoClose: 2000,
        theme: 'dark'
      });
      return true;
    } catch (error: AxiosError | any) {
      console.error('Error updating default pairing: ', error);
      let message = 'Error updating default pairing';
      if (error instanceof AxiosError) {
        message = error?.response?.data.message;
      }
      openToast({
        type: 'error',
        label: t(message),
        autoClose: 2000,
        theme: 'dark'
      });
      return false;
    }
  };

  useEffect(() => {
    if (isShown) {
      getPairs();
    }
  }, [isShown]);

  async function removeDefaultPairing (props: any): Promise<void> {
    const id = accountToFcp ? accountToFcp.accountToFcpId : 0;
    const clearedDefaultPairs = {
      fcp_field: '',
      geotab_field: '',
      wildcard_search: false,
      account_to_fcp_id: id
    };
    props.setSubmitting(true);
    const hide = await handleSubmit(clearedDefaultPairs);
    props.setSubmitting(false);
    if (hide) {
      setIsShown(false);
    }
  }

  const findPairByAccountToFcpId = (id: number): DefaultPairing | undefined => {
    return pairs.find((pair) => pair.account_to_fcp_id === id);
  };

  const handleProviderChange = async (name: FuelCardProvider, setValues: (values: any) => void): Promise<void> => {
    const acct = accountToFcpData[0];
    const emptyPair: DefaultPairing = {
      fcp_field: '',
      geotab_field: '',
      wildcard_search: 0
    };
    if (acct) {
      setAccountToFcp(acct);
      setAccountToFcpId(acct.accountToFcpId);
      const providerName = acct.name ?? '';
      setFcpName(providerName);
      const newSelectedPair = findPairByAccountToFcpId(acct.accountToFcpId);
      if (newSelectedPair) {
        handleSelectPair(newSelectedPair);
        setValues({
          name: providerName,
          fcp_field: newSelectedPair.fcp_field,
          geotab_field: newSelectedPair.geotab_field,
          wildcard_search: newSelectedPair.wildcard_search ?? false
        });
      } else {
        handleSelectPair(emptyPair);
        setValues({
          name: providerName,
          fcp_field: '',
          geotab_field: '',
          wildcard_search: false
        });
      }
      if (acct.preferredMatch === 'vehicle') {
        setFcpFieldOptions(vehicleFcpFields);
        setGeotabFieldOptions(vehicleGeotabFields);
      } else if (acct.preferredMatch === 'driver') {
        setFcpFieldOptions(driverFcpFields);
        setGeotabFieldOptions(driverGeotabFields);
      }
    } else {
      handleSelectPair(emptyPair);
      setValues({
        name: '',
        fcp_field: '',
        geotab_field: '',
        wildcard_search: false
      });
    }
  };

  return (
    <>
      <IconButton
        icon="gear"
        onClick={() => {
          setIsShown(true);
        }}
      />
      <Modal
        header={
          <div className="flex items-center gap-x-2">
            <Icon name="gear" />
            <p>{t('Fuel Card Settings')}</p>
          </div>
        }
        show={isShown}
        onHide={() => {
          setIsShown(false);
        }}
        maxWidth={350}
      >
        {loading
          ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '300px'
                }}
              >
                <CircularProgress />
              </div>
            )
          : (
          <Formik
            enableReinitialize
            validateOnMount
            initialValues={initialFormValues}
            validationSchema={defaultPairingsSchema}
            onSubmit={async (values, { setSubmitting }) => {
              if (!accountToFcpId) {
                return false;
              }
              setSubmitting(true);
              const submittedSettings = {
                fcp_field: values.fcp_field,
                geotab_field: values.geotab_field,
                wildcard_search: values.wildcard_search,
                account_to_fcp_id: accountToFcpId
              };
              const hide = await handleSubmit(submittedSettings);
              setSubmitting(false);
              if (hide) {
                setIsShown(false);
              }
            }}
          >
            {(props: any) => (
              <form onSubmit={props.handleSubmit} className="mt-4">
                {props.isSubmitting && (
                  <div className="loading-overlay">
                    <CircularProgress />
                  </div>
                )}
                <div className="flex flex-col gap-y-2">
                  <div className="flex items-center gap-x-2">
                    <p className="text-xs uppercase font-bold">{t('Default Pairing')}</p>
                    <FuelCardSettingsTooltip />
                  </div>
                  <div className="flex flex-col gap-y-4">
                    <Input
                      name="name"
                      type="select"
                      options={accountToFcpData.map((acct: AccountToFcp) => acct.name)}
                      onChange={(option: FuelCardProvider): void => {
                        void handleProviderChange(option, props.setValues);
                      }}
                      label={t('Provider')}
                      defaultValue={props.values.name}
                    />
                    <Input
                      name="fcp_field"
                      type="select"
                      label={t('Select FCP Field')}
                      options={fcpFieldOptions}
                      defaultValue={props.values.fcp_field}
                      onChange={(value: string) => {
                        props.setFieldValue('fcp_field', value);
                      }}
                      placeholder={t('Select FCP Field')}
                    />
                    <Input
                      name="geotab_field"
                      type="select"
                      label={t('Select Geotab Field')}
                      options={geotabFieldOptions}
                      defaultValue={props.values.geotab_field}
                      onChange={(value: string) => {
                        props.setFieldValue('geotab_field', value);
                      }}
                    />
                    <Switch
                      label={t('Wildcard Search')}
                      checked={props.values.wildcard_search}
                      onClick={(newValue: boolean) => {
                        props.setFieldValue('wildcard_search', newValue);
                      }}
                    />
                  </div>
                  <Button
                    disabled={props.isSubmitting}
                    style={{ margin: '15px 0px -10px' }}
                    type="button"
                    onClick={() => {
                      removeDefaultPairing(props);
                    }}
                  >
                    {t('Remove Default Pairing')}
                  </Button>
                  <Button
                    disabled={
                      props.isSubmitting ||
                      !props.values.name ||
                      !props.values.fcp_field ||
                      !props.values.geotab_field
                    }
                    className="!px-12"
                    type="submit"
                  >
                    {t('Submit')}
                  </Button>
                </div>
              </form>
            )}
          </Formik>
            )}
      </Modal>
    </>
  );
};

export default FuelCardSettingsModal;
