import React, { useEffect, useState } from 'react';
import Modal from '../../Modal';
import Icon from '../../Icon';
import { Formik } from 'formik';
import Button from '../../Button';
import Checkbox from '../../Formik/Checkbox';
import Input from '../../Formik/Input';
import classNames from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';
import '../../../../index.css';
import axios from 'axios';
import { type User } from '../../../../routes/Authorized/Users/UserTypes';
import { openToast } from '../../../../helpers/toast';
import { defaultTransactionAlerts, iconColorMap, iconTypeMapping } from '../../../../helpers/types';
import i18n, { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import { appState } from '../../../..';
import { type TransactionAlerts } from '../../../../helpers/types';
import { getParentAuthHeader } from '../../../../helpers/api';
import * as Yup from 'yup';

export const defaultDays = [1, 7, 14, 30, 365];

interface Props {
  user: User | null;
  show: boolean;
  onHide: () => void;
}

const UserSettingsEditorModal: React.FC<Props> = ({
  user,
  show,
  onHide
}) => {
  const showAlerts = localStorage.getItem('accountName') === 'CDOT';
  const [showCustomInputMostExceptions, setShowCustomInputMostExceptions] = useState(false);
  const [showCustomInputFuelingEfficiency, setShowCustomInputFuelingEfficiency] = useState(false);
  const [step, setStep] = useState<number>(1);
  const [userSettings, setUserSettings] = useState<any>({});
  const [defaultMostExceptionsPeriod, setDefaultMostExceptionsPeriod] = useState<string>('');
  const [defaultFuelingEfficiencyPeriod, setDefaultFuelingEfficiencyPeriod] = useState<string>('');
  const [emailSubscriptions, setEmailSubscriptions] = useState<TransactionAlerts>(defaultTransactionAlerts);
  const [btAlertSettings, setBtAlertSettings] = useState<TransactionAlerts>(defaultTransactionAlerts);
  const [woAlertSettings, setWoAlertSettings] = useState<TransactionAlerts>(defaultTransactionAlerts);
  const [accountSettings, setAccountSettings] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  const userSettingsSchema = Yup.object().shape({
    custom_fueling_efficiency_days: Yup.number().required('Required')
      .positive('Please enter a positive number')
      .typeError('Please enter a number'),
    custom_days_displayed: Yup.number().required('Required')
      .positive('Please enter a positive number')
      .typeError('Please enter a number')
  });

  const isCustom = (newValue: any, method: 'most-exceptions' | 'fueling-efficiency'): void => {
    console.log(newValue);
    if (method === 'most-exceptions') {
      setDefaultMostExceptionsPeriod(newValue);
      setShowCustomInputMostExceptions(newValue === 'custom');
    } else if (method === 'fueling-efficiency') {
      setDefaultFuelingEfficiencyPeriod(newValue);
      setShowCustomInputFuelingEfficiency(newValue === 'custom');
    }
  };

  const fetchUserSettings = async (): Promise<void> => {
    try {
      setLoading(true);
      if (!user) {
        return;
      }
      const userSettingsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/user-settings?filter=user_id:${user.id}(eq)`);
      if (!userSettingsResponse.data.data.length) {
        return;
      }
      setUserSettings(userSettingsResponse.data.data[0]);
      setEmailSubscriptions(userSettingsResponse.data.data[0].email_subscriptions);
      setBtAlertSettings(userSettingsResponse.data.data[0].bt_alert_settings);
      setWoAlertSettings(userSettingsResponse.data.data[0].wo_alert_settings);

      const accountSettingsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/account-settings?filter=account_id:${appState.accountId.value}(eq)`);
      if (!accountSettingsResponse.data.data.length) {
        return;
      }
      setAccountSettings(accountSettingsResponse.data.data[0]);
      console.log(userSettingsResponse);

      setDefaultMostExceptionsPeriod(userSettingsResponse.data.data[0]?.default_days_displayed);
      setDefaultFuelingEfficiencyPeriod(userSettingsResponse.data.data[0]?.default_fueling_efficiency_days);
      if (!defaultDays.includes(userSettingsResponse.data.data[0]?.default_days_displayed)) {
        setShowCustomInputMostExceptions(true);
      }
      if (!defaultDays.includes(userSettingsResponse.data.data[0]?.default_fueling_efficiency_days)) {
        setShowCustomInputFuelingEfficiency(true);
      }
    } catch (error) {
      console.error('Error fetching user settings: ', error);
    } finally {
      setLoading(false);
    }
  };

  const updateUserSettings = async (settingsSubmitted: any): Promise<void> => {
    try {
      if (!settingsSubmitted) {
        return;
      }
      if (!userSettings || !accountSettings) {
        return;
      }

      settingsSubmitted.userId = userSettings.user_id;
      settingsSubmitted.userSettingsId = userSettings.id;
      settingsSubmitted.accountId = accountSettings.account_id;
      settingsSubmitted.accountSettingsId = accountSettings.id;

      if (settingsSubmitted.default_days_displayed === 'custom') {
        settingsSubmitted.default_days_displayed = parseInt(settingsSubmitted.custom_days_displayed);
      } else {
        settingsSubmitted.default_days_displayed = parseInt(settingsSubmitted.default_days_displayed);
      }

      if (settingsSubmitted.default_fueling_efficiency_days === 'custom') {
        settingsSubmitted.default_fueling_efficiency_days = parseInt(settingsSubmitted.custom_fueling_efficiency_days);
      } else {
        settingsSubmitted.default_fueling_efficiency_days = parseInt(settingsSubmitted.default_fueling_efficiency_days);
      }

      settingsSubmitted.bad_transactions_threshold = settingsSubmitted.bad_transactions_threshold
        ? settingsSubmitted.bad_transactions_threshold
        : userSettings.bad_transactions_threshold;
      settingsSubmitted.transactions_name_displayed = settingsSubmitted.transactions_name_displayed ?? userSettings.transactions_name_displayed;
      settingsSubmitted.unit_of_measure = (settingsSubmitted.unit_of_measure ?? userSettings.unit_of_measure) ?? 'imperial';
      settingsSubmitted._method = 'PUT';

      const headers = getParentAuthHeader();
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/user-settings/${settingsSubmitted.userSettingsId}`,
        settingsSubmitted,
        headers ? { headers } : undefined
      );
      openToast({ type: 'success', label: t('User settings updated'), autoClose: 2000, theme: 'dark' });
    } catch (error: any) {
      if (error.response.status === 403) {
        openToast({ type: 'error', label: t('You are not authorized to edit user settings'), autoClose: 2000, theme: 'dark' });
      } else {
        openToast({ type: 'error', label: t('Error updating user settings'), autoClose: 2000, theme: 'dark' });
      }
      console.log('Error updating user settings: ', error);
    }
  };
  useEffect(() => {
    if (show) {
      setStep(1);
      void fetchUserSettings();
    }
  }, [show]);

  return (
    <Modal
      header={(
        <div className="flex items-center gap-x-2">
          <Icon name={!user?.id ? 'plus' : 'gear'} />
          <p>{!user?.id ? t('Add') : t('Edit')} {t('User Settings')}</p>
        </div>
      )}
      show={show}
      onHide={onHide}
      maxWidth={i18n.resolvedLanguage === 'en' ? 660 : 1000}
      customZIndex={1001}
    >
      <div
        className="flex items-center text-xs mt-4 mb-6 border border-custom-gray-normal rounded-full overflow-hidden"
      >
        <button
          type="button"
          className={classNames('px-6 py-1 whitespace-nowrap', step === 1 && 'bg-custom-blue-normal')}
          onClick={() => {
            setStep(1);
          }}
        >
          {t('Receive Emails')}
        </button>
        <button
          type="button"
          className={classNames('flex-1 6 py-1 whitespace-nowrap border-x border-custom-gray-normal', step === 2 && 'bg-custom-blue-normal')}
          onClick={() => {
            setStep(2);
          }}
        >
          {t('Transaction Discrepancy')}
        </button>
        <button
          type="button"
          className={classNames('flex-1 6 py-1 whitespace-nowrap border-r border-custom-gray-normal', step === 3 && 'bg-custom-blue-normal')}
          onClick={() => {
            setStep(3);
          }}
        >
          {t('Most Exceptions')}
        </button>
        <button
          type="button"
          className={classNames('px-6 py-1 whitespace-nowrap', step === 4 && 'bg-custom-blue-normal')}
          onClick={() => {
            setStep(4);
          }}
        >
          {t('Fueling Efficiency')}
        </button>
      </div>
      <Formik
        validationSchema={userSettingsSchema}
        enableReinitialize
        initialValues={{
          ...user,
          email_subscriptions: emailSubscriptions,
          bt_alert_settings: btAlertSettings,
          wo_alert_settings: woAlertSettings,
          default_days_displayed: userSettings.default_days_displayed,
          custom_days_displayed: userSettings.default_days_displayed,
          default_fueling_efficiency_days: userSettings.default_fueling_efficiency_days,
          custom_fueling_efficiency_days: userSettings.default_fueling_efficiency_days
        }}
        onSubmit={async (settingsSubmitted, { setSubmitting }) => {
          await updateUserSettings(settingsSubmitted);
          setSubmitting(false);
          onHide();
        }}
      >
        {(props: any) => {
          return (
            <form
              onSubmit={props.handleSubmit}
              className="mt-4"
            >
              {(props.isSubmitting || loading) && (
                <div className="loading-overlay">
                  <CircularProgress />
                </div>
              )}
              {step === 1 && (
                <div className="flex flex-col gap-y-2">
                  <p className="text-xs uppercase font-bold">{t('Receive Emails')}</p>
                  <Checkbox
                    switchType
                    name="email_subscriptions.no_vehicle_location"
                    label={(
                      <div className="flex items-center gap-x-1">
                        <div
                          className={`${iconColorMap[iconTypeMapping.no_vehicle_location]} w-[1.1rem] flex items-center justify-center`}
                        >
                          <Icon name={iconTypeMapping.no_vehicle_location} />
                        </div>
                        <p>{t('No Vehicle Location')}</p>
                      </div>
                    )}
                  />
                  <Checkbox
                    switchType
                    name="email_subscriptions.location_mismatch"
                    label={(
                      <div className="flex items-center gap-x-1">
                        <div
                          className={`${iconColorMap[iconTypeMapping.location_mismatch]} w-[1.1rem] flex items-center justify-center`}
                        >
                          <Icon name={iconTypeMapping.location_mismatch} />
                        </div>
                        <p>{t('Location Mismatch')}</p>
                      </div>
                    )}
                  />
                  <Checkbox
                    switchType
                    name="email_subscriptions.fuel_level_mismatch"
                    label={(
                      <div className="flex items-center gap-x-1">
                        <div
                          className={`${iconColorMap[iconTypeMapping.fuel_level_mismatch]} w-[1.1rem] flex items-center justify-center`}
                        >
                          <Icon name={iconTypeMapping.fuel_level_mismatch} />
                        </div>
                        <p>{t('Fuel Level Mismatch')}</p>
                      </div>
                    )}
                  />
                  <Checkbox
                    switchType
                    name="email_subscriptions.fuel_type_mismatch"
                    label={(
                      <div className="flex items-center gap-x-1">
                        <div
                          className={`${iconColorMap[iconTypeMapping.fuel_type_mismatch]} w-[1.1rem] flex items-center justify-center`}
                        >
                          <Icon name={iconTypeMapping.fuel_type_mismatch} />
                        </div>
                        <p>{t('Fuel Type Mismatch')}</p>
                      </div>
                    )}
                  />
                  <Checkbox
                    switchType
                    name="email_subscriptions.filling_time_alert"
                    label={(
                      <div className="flex items-center gap-x-1">
                        <div
                          className={`${iconColorMap[iconTypeMapping.filling_time_alert]} w-[1.1rem] flex items-center justify-center`}
                        >
                          <Icon name={iconTypeMapping.filling_time_alert} />
                        </div>
                        <p>{t('Filling Time Alert')}</p>
                      </div>
                    )}
                  />
                  <Checkbox
                    switchType
                    name="email_subscriptions.filling_percent_alert"
                    label={(
                      <div className="flex items-center gap-x-1">
                        <div
                          className={`${iconColorMap[iconTypeMapping.filling_percent_alert]} w-[1.1rem] flex items-center justify-center`}
                        >
                          <Icon name={iconTypeMapping.filling_percent_alert} />
                        </div>
                        <p>{t('Filling Percent Alert')}</p>
                      </div>
                    )}
                  />
                  {showAlerts && (
                    <Checkbox
                      switchType
                      name="email_subscriptions.tank_capacity_mismatch"
                      label={(
                        <div className="flex items-center gap-x-1">
                          <div
                            className={`${iconColorMap[iconTypeMapping.tank_capacity_mismatch]} w-[1.1rem] flex items-center justify-center`}
                          >
                            <Icon name={iconTypeMapping.tank_capacity_mismatch} />
                          </div>
                          <p>{t('Tank Capacity Mismatch')}</p>
                        </div>
                      )}
                    />
                  )}
                  {showAlerts && (
                    <Checkbox
                      switchType
                      name="email_subscriptions.out_of_state_alert"
                      label={(
                        <div className="flex items-center gap-x-1">
                          <div
                            className={`${iconColorMap[iconTypeMapping.out_of_state_alert]} w-[1.1rem] flex items-center justify-center`}
                          >
                            <Icon name={iconTypeMapping.out_of_state_alert} />
                          </div>
                          <p>{t('Out of State')}</p>
                        </div>
                      )}
                    />
                  )}
                </div>
              )}
              {step === 2 && (
                <div className="flex flex-col gap-y-2">
                  <p className="text-xs uppercase font-bold">{t('Transaction Discrepancy Settings')}</p>
                  <div className="mb-3">
                    <Input
                      name="transactions_name_displayed"
                      type="select"
                      label={`${t('Name Displayed On Rows')}`}
                      options={[
                        { key: t('Driver Name'), value: 'driver_name' },
                        { key: t('Name On Card'), value: 'name_on_card' }
                      ]}
                      defaultValue={(userSettings)?.transactions_name_displayed ?? 'driver_name'}
                    />
                  </div>
                  <Checkbox
                    switchType
                    name="bt_alert_settings.no_vehicle_location"
                    label={(
                      <div className="flex items-center gap-x-1">
                        <div
                          className={`${iconColorMap[iconTypeMapping.no_vehicle_location]} w-[1.1rem] flex items-center justify-center`}
                        >
                          <Icon name={iconTypeMapping.no_vehicle_location} />
                        </div>
                        <p>{t('No Vehicle Location')}</p>
                      </div>
                    )}
                  />
                  <Checkbox
                    switchType
                    name="bt_alert_settings.location_mismatch"
                    label={(
                      <div className="flex items-center gap-x-1">
                        <div
                          className={`${iconColorMap[iconTypeMapping.location_mismatch]} w-[1.1rem] flex items-center justify-center`}
                        >
                          <Icon name={iconTypeMapping.location_mismatch} />
                        </div>
                        <p>{t('Location Mismatch')}</p>
                      </div>
                    )}
                  />
                  <Checkbox
                    switchType
                    name="bt_alert_settings.fuel_level_mismatch"
                    label={(
                      <div className="flex items-center gap-x-1">
                        <div
                          className={`${iconColorMap[iconTypeMapping.fuel_level_mismatch]} w-[1.1rem] flex items-center justify-center`}
                        >
                          <Icon name={iconTypeMapping.fuel_level_mismatch} />
                        </div>
                        <p>{t('Fuel Level Mismatch')}</p>
                      </div>
                    )}
                  />
                  <Checkbox
                    switchType
                    name="bt_alert_settings.fuel_type_mismatch"
                    label={(
                      <div className="flex items-center gap-x-1">
                        <div
                          className={`${iconColorMap[iconTypeMapping.fuel_type_mismatch]} w-[1.1rem] flex items-center justify-center`}
                        >
                          <Icon name={iconTypeMapping.fuel_type_mismatch} />
                        </div>
                        <p>{t('Fuel Type Mismatch')}</p>
                      </div>
                    )}
                  />
                  <Checkbox
                    switchType
                    name="bt_alert_settings.filling_time_alert"
                    label={(
                      <div className="flex items-center gap-x-1">
                        <div
                          className={`${iconColorMap[iconTypeMapping.filling_time_alert]} w-[1.1rem] flex items-center justify-center`}
                        >
                          <Icon name={iconTypeMapping.filling_time_alert} />
                        </div>
                        <p>{t('Filling Time Alert')}</p>
                      </div>
                    )}
                  />
                  <Checkbox
                    switchType
                    name="bt_alert_settings.filling_percent_alert"
                    label={(
                      <div className="flex items-center gap-x-1">
                        <div
                          className={`${iconColorMap[iconTypeMapping.filling_percent_alert]} w-[1.1rem] flex items-center justify-center`}
                        >
                          <Icon name={iconTypeMapping.filling_percent_alert} />
                        </div>
                        <p>{t('Filling Percent Alert')}</p>
                      </div>
                    )}
                  />
                  {showAlerts && (
                    <Checkbox
                      switchType
                      name="bt_alert_settings.tank_capacity_mismatch"
                      label={(
                        <div className="flex items-center gap-x-1">
                          <div
                            className={`${iconColorMap[iconTypeMapping.tank_capacity_mismatch]} w-[1.1rem] flex items-center justify-center`}
                          >
                            <Icon name={iconTypeMapping.tank_capacity_mismatch} />
                          </div>
                          <p>{t('Tank Capacity Mismatch')}</p>
                        </div>
                      )}
                    />
                  )}
                  {showAlerts && (
                    <Checkbox
                      switchType
                      name="bt_alert_settings.out_of_state_alert"
                      label={(
                        <div className="flex items-center gap-x-1">
                          <div
                            className={`${iconColorMap[iconTypeMapping.out_of_state_alert]} w-[1.1rem] flex items-center justify-center`}
                          >
                            <Icon name={iconTypeMapping.out_of_state_alert} />
                          </div>
                          <p>{t('Out of State')}</p>
                        </div>
                      )}
                    />
                  )}
                </div>
              )}
              {step === 3 && (
                <div className="flex flex-col gap-y-2">
                  <p className="text-xs uppercase font-bold">{t('Most Exceptions Settings')}</p>
                  <div className="mb-3 flex flex-col gap-y-3">
                    <Input
                      name="bad_transactions_threshold"
                      type="number"
                      label={`${t('Transaction Discrepancy Threshold')}"`}
                      value={(userSettings)?.bad_transactions_threshold ?? ''}
                      defaultValue={(userSettings)?.bad_transactions_threshold ?? ''}
                    />
                    <Input
                      name="default_days_displayed"
                      type="select"
                      label={t('Default Period')}
                      options={[
                        { key: t('Yesterday'), value: '1' },
                        { key: t('Last Week'), value: '7' },
                        { key: t('Last Two Weeks'), value: '14' },
                        { key: t('Last Month'), value: '30' },
                        { key: t('Last Year'), value: '365' },
                        { key: t('Custom...'), value: 'custom' }
                      ]}
                      onChange={(newValue) => {
                        isCustom(newValue, 'most-exceptions');
                      }}
                      defaultValue={showCustomInputMostExceptions ? 'custom' : defaultMostExceptionsPeriod ? (defaultMostExceptionsPeriod).toString() : ''}
                    />
                    {showCustomInputMostExceptions &&
                      <Input
                        name="custom_days_displayed"
                        type="number"
                        label="Number of Days"
                        defaultValue={userSettings?.default_days_displayed ? userSettings?.default_days_displayed : ''}
                      />}
                  </div>
                  <Checkbox
                    switchType
                    name="wo_alert_settings.no_vehicle_location"
                    label={(
                      <div className="flex items-center gap-x-1">
                        <div
                          className={`${iconColorMap[iconTypeMapping.no_vehicle_location]} w-[1.1rem] flex items-center justify-center`}
                        >
                          <Icon name={iconTypeMapping.no_vehicle_location} />
                        </div>
                        <p>{t('No Vehicle Location')}</p>
                      </div>
                    )}
                  />
                  <Checkbox
                    switchType
                    name="wo_alert_settings.location_mismatch"
                    label={(
                      <div className="flex items-center gap-x-1">
                        <div
                          className={`${iconColorMap[iconTypeMapping.location_mismatch]} w-[1.1rem] flex items-center justify-center`}
                        >
                          <Icon name={iconTypeMapping.location_mismatch} />
                        </div>
                        <p>{t('Location Mismatch')}</p>
                      </div>
                    )}
                  />
                  <Checkbox
                    switchType
                    name="wo_alert_settings.fuel_level_mismatch"
                    label={(
                      <div className="flex items-center gap-x-1">
                        <div
                          className={`${iconColorMap[iconTypeMapping.fuel_level_mismatch]} w-[1.1rem] flex items-center justify-center`}
                        >
                          <Icon name={iconTypeMapping.fuel_level_mismatch} />
                        </div>
                        <p>{t('Fuel Level Mismatch')}</p>
                      </div>
                    )}
                  />
                  <Checkbox
                    switchType
                    name="wo_alert_settings.fuel_type_mismatch"
                    label={(
                      <div className="flex items-center gap-x-1">
                        <div
                          className={`${iconColorMap[iconTypeMapping.fuel_type_mismatch]} w-[1.1rem] flex items-center justify-center`}
                        >
                          <Icon name={iconTypeMapping.fuel_type_mismatch} />
                        </div>
                        <p>{t('Fuel Type Mismatch')}</p>
                      </div>
                    )}
                  />
                  <Checkbox
                    switchType
                    name="wo_alert_settings.filling_time_alert"
                    label={(
                      <div className="flex items-center gap-x-1">
                        <div
                          className={`${iconColorMap[iconTypeMapping.filling_time_alert]} w-[1.1rem] flex items-center justify-center`}
                        >
                          <Icon name={iconTypeMapping.filling_time_alert} />
                        </div>
                        <p>{t('Filling Time Alert')}</p>
                      </div>
                    )}
                  />
                  <Checkbox
                    switchType
                    name="wo_alert_settings.filling_percent_alert"
                    label={(
                      <div className="flex items-center gap-x-1">
                        <div
                          className={`${iconColorMap[iconTypeMapping.filling_percent_alert]} w-[1.1rem] flex items-center justify-center`}
                        >
                          <Icon name={iconTypeMapping.filling_percent_alert} />
                        </div>
                        <p>{t('Filling Percent Alert')}</p>
                      </div>
                    )}
                  />
                  {showAlerts && (
                    <Checkbox
                      switchType
                      name="wo_alert_settings.tank_capacity_mismatch"
                      label={(
                        <div className="flex items-center gap-x-1">
                          <div
                            className={`${iconColorMap[iconTypeMapping.tank_capacity_mismatch]} w-[1.1rem] flex items-center justify-center`}
                          >
                            <Icon name={iconTypeMapping.tank_capacity_mismatch} />
                          </div>
                          <p>{t('Tank Capacity Mismatch')}</p>
                        </div>
                      )}
                    />
                  )}
                  {showAlerts && (
                    <Checkbox
                      switchType
                      name="wo_alert_settings.out_of_state_alert"
                      label={(
                        <div className="flex items-center gap-x-1">
                          <div
                            className={`${iconColorMap[iconTypeMapping.out_of_state_alert]} w-[1.1rem] flex items-center justify-center`}
                          >
                            <Icon name={iconTypeMapping.out_of_state_alert} />
                          </div>
                          <p>{t('Out of State')}</p>
                        </div>
                      )}
                    />
                  )}
                </div>
              )}
              {step === 4 && (
                <div className="flex flex-col gap-y-2">
                  <p className="text-xs uppercase font-bold">{t('Fueling Efficiency Settings')}</p>
                  <div className="mb-3 flex flex-col gap-y-3">
                    <Input
                      name="default_fueling_efficiency_days"
                      type="select"
                      label={t('Default Period')}
                      options={[
                        { key: t('Yesterday'), value: '1' },
                        { key: t('Last Week'), value: '7' },
                        { key: t('Last Two Weeks'), value: '14' },
                        { key: t('Last Month'), value: '30' },
                        { key: t('Last Year'), value: '365' },
                        { key: t('Custom...'), value: 'custom' }
                      ]}
                      onChange={(newValue) => {
                        console.log('newValue');
                        console.log(newValue);
                        isCustom(newValue, 'fueling-efficiency');
                      }}
                      defaultValue={showCustomInputFuelingEfficiency ? 'custom' : defaultFuelingEfficiencyPeriod ? (defaultFuelingEfficiencyPeriod).toString() : ''}
                    />
                    {showCustomInputFuelingEfficiency &&
                      <Input
                        name="custom_fueling_efficiency_days"
                        type="number"
                        label="Number of Days"
                        defaultValue={userSettings?.default_fueling_efficiency_days ? userSettings?.default_fueling_efficiency_days : ''}
                      />}
                  </div>
                </div>
              )}
              <div className="flex items-center gap-x-3">
                <Button
                  disabled={props.isSubmitting || loading}
                >
                  {t('Submit')}
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default withTranslation()(UserSettingsEditorModal);
