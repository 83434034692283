import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import MapIcon from '@mui/icons-material/Map';

interface MapToggleProps {
  map: google.maps.Map | null;
}

const MapToggle: React.FC<MapToggleProps> = ({ map }) => {
  const [mapType, setMapType] = useState<'roadmap' | 'satellite'>('roadmap');

  const toggleMapType = (): void => {
    if (map) {
      const newType = mapType === 'roadmap' ? 'satellite' : 'roadmap';
      map.setMapTypeId(newType);
      setMapType(newType);
    }
  };

  return (
    <IconButton
      onClick={toggleMapType}
      style={{
        backgroundColor: 'gray',
        position: 'absolute',
        top: 10,
        right: 10,
        zIndex: 5
      }}
    >
      {mapType === 'roadmap' ? <SatelliteAltIcon /> : <MapIcon />}
    </IconButton>
  );
};

export default MapToggle;
