import React from 'react';
import Switch from '../../ui/shared/Switch';
import { iconColorMap, iconTypeMapping } from '../types';
import Icon from '../../ui/shared/Icon';
import { t } from 'i18next';

interface AlertConfig {
  key: string;
  label: string;
}

export const alertTypes: AlertConfig[] = [
  { key: 'no_vehicle_location', label: 'No Vehicle Location' },
  { key: 'location_mismatch', label: 'Location Mismatch' },
  { key: 'fuel_type_mismatch', label: 'Fuel Type Mismatch' },
  { key: 'fuel_level_mismatch', label: 'Fuel Level Mismatch' },
  { key: 'filling_time_alert', label: 'Filling Time Alert' },
  { key: 'filling_percent_alert', label: 'Filling Percent Alert' }
];

interface AlertTypeProps {
  alertKey: string;
  label: string;
  checked: boolean;
  addAlert: (checked: boolean, alertKey: string) => void;
}

export const AlertType: React.FC<AlertTypeProps> = ({ alertKey, label, checked, addAlert }) => {
  return (
    <div className="flex items-center gap-x-2">
      <Switch
        checked={checked}
        onClick={(checked) => {
          addAlert(checked, alertKey);
        }}
      />
      <div className="flex items-center gap-x-1">
        <div
          className={`${iconColorMap[iconTypeMapping[alertKey]]} w-[1.1rem] flex items-center justify-center`}
        >
          <Icon name={iconTypeMapping[alertKey]} />
        </div>
        <p className="text-sm">{t(label)}</p>
      </div>
    </div>
  );
};
